import jwtDecode from 'jwt-decode';
import { logger } from '@allergan-data-labs/component-library/src/datadog/dataDog';
import { inspect } from 'util';

const BasicAuthClientFactory = (key: string) => ({
  validateToken: () =>
    new Promise((resolve, reject) => {
      let expireTime: number = 0;

      if (!localStorage[key]) {
        reject('No token present');
      }

      try {
        interface ExpiringToken {
          exp: number;
        }

        const { exp } = jwtDecode(localStorage[key]) as ExpiringToken;

        // It will be used as a log information in datadog
        expireTime = exp;

        if (!exp || new Date() > new Date(exp * 1000)) {
          logger.info('Token is expired or has invalid expiration', {
            id: 'basicAuth.validateToken.expired',
            data: {
              expireTime,
              currentDate: new Date(),
              expireDate: new Date(exp * 1000),
            },
          });
          reject('Token is expired or has invalid expiration');
        }
      } catch (error) {
        logger.error('Unexpected error occurred during token validation', {
          id: 'basicAuth.validateToken',
          error: inspect(error),
        });
        reject(error.message);
      }

      logger.debug('Token is valid', {
        id: 'basicAuth.validateToken.completed',
        data: {
          expireTime,
          expireDate: new Date(expireTime * 1000),
          currentDate: new Date(),
        },
      });

      resolve('Token is valid');
    }),
  getAccessToken: () => {
    return localStorage[key];
  },
  storeToken: (value: string) => {
    localStorage[key] = value;
  },
  subscribeToTokenInvalidation: function (callback: () => void) {
    const getAccessTokenHandler = (event: StorageEvent) => {
      if (event.key === key && (!event.newValue || event.newValue === '{}')) {
        callback();
      }
    };

    // Logout all tabs if one tab logs out
    window.addEventListener('storage', getAccessTokenHandler);

    this.validateToken().catch(() => {
      callback();
    });

    const tokenCheckInterval = setInterval(() => {
      this.validateToken().catch(() => {
        callback();
      });
    }, 60000);

    const unsubscribe = () => {
      clearInterval(tokenCheckInterval);
      window.removeEventListener('storage', getAccessTokenHandler);
    };

    return unsubscribe;
  },
  signOut: () => {
    return localStorage.removeItem(key);
  },
});

export { BasicAuthClientFactory };
