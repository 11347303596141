import React from 'react';
import { Permission } from './permission';
import { AuthContext } from './AuthContext';

const useAlleIam = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAlleIam must be used within a AuthProvider');
  }

  const isAllowed = React.useCallback(
    (permission: Permission): boolean => {
      return (context.permissions ?? []).indexOf(permission) >= 0;
    },
    [context.permissions]
  );

  return {
    isAllowed,
  };
};

export { useAlleIam };
