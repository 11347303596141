import React, { forwardRef } from 'react';
import { Avatar } from '@chakra-ui/react';

interface UserAvatarProps {
  label: string;
  onClick?: () => void;
  isMenuAction?: boolean;
}

const UserAvatar = forwardRef<HTMLDivElement, UserAvatarProps>(
  ({ label, onClick, isMenuAction = false }, ref) => {
    return (
      <Avatar
        name={label}
        color="light.Text/Neutral/Default"
        bg="transparent"
        ref={ref}
        data-testid="user-avatar-container"
        onClick={onClick}
        borderColor="light.Border/Neutral/Medium 2"
        borderWidth="1px"
        borderStyle="solid"
        height={isMenuAction ? '32px' : '40px'}
        width={isMenuAction ? '32px' : '40px'}
        cursor={isMenuAction ? 'pointer' : 'initial'}
        sx={{
          '.chakra-avatar__initials': {
            fontFamily: 'Graphik',
            fontSize: '12px',
            fontWeight: 400,
            height: 'auto',
            width: '16px',
            gap: '8px',
            display: 'flex',
            alignSelf: 'stretch',
            alignItems: 'center',
            padding: '11px 12px',
          },
        }}
      />
    );
  }
);

const getAvatarTriggerElement = (label: string) =>
  forwardRef<HTMLDivElement, Omit<UserAvatarProps, 'label'>>((props, ref) => (
    <UserAvatar label={label} isMenuAction={true} ref={ref} {...props} />
  ));

UserAvatar.displayName = 'UserAvatar';

export { UserAvatar, getAvatarTriggerElement };
