import { a4aFeatureKeys } from '@allergan-data-labs/split-feature-flag/src/admin';
import { useAlleTreatments } from '@allergan-data-labs/split-feature-flag/src/useAlleTreatments';

const useAdminFeatureFlags = () => {
  const treatments = useAlleTreatments({
    splitNames: [
      a4aFeatureKeys.admin.a4aHelloWorld,
      a4aFeatureKeys.admin.a4aAlleElementsLibraryEnabled,
      a4aFeatureKeys.admin.a4aBusinessPageViewEnabled,
      a4aFeatureKeys.admin.patientSummary.offerHistory,
      a4aFeatureKeys.admin.patientSummary.profileActivityHistory,
      a4aFeatureKeys.admin.managePatientLinks.preferredProvider,
      a4aFeatureKeys.admin.managePatientLinks.mergePatient,
      a4aFeatureKeys.admin.managePatientLinks.unmergePatient,
      a4aFeatureKeys.admin.transactionOptions.viewEdits,
      a4aFeatureKeys.admin.managePatientLinks.activateAccount,
      a4aFeatureKeys.admin.offerBuilder.a4aOfferListPage,
      a4aFeatureKeys.admin.offerBuilder.domainOffers,
      a4aFeatureKeys.admin.offerBuilder.giftCardsChangeRequestEndDateToggle,
      a4aFeatureKeys.admin.offerBuilder.repDispensedFlashEnabled,
      a4aFeatureKeys.admin.adminCheckoutTracking,
      a4aFeatureKeys.admin.giftCardDetailsContentfulIsi,
      a4aFeatureKeys.admin.patientSummary.pointsCap,
      a4aFeatureKeys.admin.communicationPreferences.saveButton,
      a4aFeatureKeys.admin.communicationPreferences.push,
      a4aFeatureKeys.admin.communicationPreferences.enabledEmail,
      a4aFeatureKeys.admin.communicationPreferences.sms,
      a4aFeatureKeys.admin.patientOffers.ngrp,
      a4aFeatureKeys.admin.core.showConsumerDeactivationAndDeletion,
      a4aFeatureKeys.admin.core.showPracticeInviteNewBusinessOwner,
      a4aFeatureKeys.admin.useIamService,
      a4aFeatureKeys.admin.practice.locationTransferTransactionStatusCard,
      a4aFeatureKeys.admin.businessUser.deleteBusinessUser,
      a4aFeatureKeys.admin.a4aNewGlobalNavigationEnabled,
    ],
  });

  const isHelloWorldEnabled =
    treatments[a4aFeatureKeys.admin.a4aHelloWorld]?.treatment === 'on';

  const isAlleElementsLibraryEnabled =
    treatments[a4aFeatureKeys.admin.a4aAlleElementsLibraryEnabled]
      ?.treatment === 'on';

  const isOfferHistoryEnabled =
    treatments[a4aFeatureKeys.admin.patientSummary.offerHistory]?.treatment ===
    'on';

  const isProfileActivityHistoryEnabled =
    treatments[a4aFeatureKeys.admin.patientSummary.profileActivityHistory]
      ?.treatment === 'on';

  const isPreferredProviderEnabled =
    treatments[a4aFeatureKeys.admin.managePatientLinks.preferredProvider]
      ?.treatment === 'on';

  const isMergePatientEnabled =
    treatments[a4aFeatureKeys.admin.managePatientLinks.mergePatient]
      ?.treatment === 'on';

  const isUnMergePatientEnabled =
    treatments[a4aFeatureKeys.admin.managePatientLinks.unmergePatient]
      ?.treatment === 'on';

  const isViewEditsEnabled =
    treatments[a4aFeatureKeys.admin.transactionOptions.viewEdits]?.treatment ===
    'on';

  const isActiveAccountEnabled =
    treatments[a4aFeatureKeys.admin.managePatientLinks.activateAccount]
      ?.treatment === 'on';

  const isA4AOfferBuilderListPageEnabled =
    treatments[a4aFeatureKeys.admin.offerBuilder.a4aOfferListPage]
      ?.treatment === 'on';

  const isGiftCardChangeRequestEnabled =
    treatments[
      a4aFeatureKeys.admin.offerBuilder.giftCardsChangeRequestEndDateToggle
    ]?.treatment === 'on';

  const isAdminCheckoutTrackingEnabled =
    treatments[a4aFeatureKeys.admin.adminCheckoutTracking]?.treatment === 'on';

  const isGiftCardDetailsContentfulIsiEnabled =
    treatments[a4aFeatureKeys.admin.giftCardDetailsContentfulIsi]?.treatment ===
    'on';

  const isPatientSummaryPointsCapEnabled =
    treatments[a4aFeatureKeys.admin.patientSummary.pointsCap]?.treatment ===
    'on';

  const isCommunicationPreferencesSaveButtonEnabled =
    treatments[a4aFeatureKeys.admin.communicationPreferences.saveButton]
      ?.treatment === 'on';

  const isNgrpAppreciationOfferEnabled =
    treatments[a4aFeatureKeys.admin.patientOffers.ngrp]?.treatment === 'on';

  const isConsumerDeactivationAndDeletionEnabled =
    treatments[a4aFeatureKeys.admin.core.showConsumerDeactivationAndDeletion]
      ?.treatment === 'on';

  const isInviteNewBusinessOwnerButtonEnabled =
    treatments[a4aFeatureKeys.admin.core.showPracticeInviteNewBusinessOwner]
      ?.treatment === 'on';

  const isIamServiceEnabled =
    treatments[a4aFeatureKeys.admin.useIamService]?.treatment === 'on';

  const isLocationTransferTransactionStatusCardEnabled =
    treatments[
      a4aFeatureKeys.admin.practice.locationTransferTransactionStatusCard
    ]?.treatment === 'on';

  const isRepDispensedFlashEnabled =
    treatments[a4aFeatureKeys.admin.offerBuilder.repDispensedFlashEnabled]
      ?.treatment === 'on';

  const isA4ABusinessPageViewEnabled =
    treatments[a4aFeatureKeys.admin.a4aBusinessPageViewEnabled]?.treatment ===
    'on';

  const isNewGlobalNavigationEnabled =
    treatments[a4aFeatureKeys.admin.a4aNewGlobalNavigationEnabled]
      ?.treatment === 'on';

  const isA4ABusinessUserDeleteUserEnabled =
    treatments[a4aFeatureKeys.admin.businessUser.deleteBusinessUser]
      ?.treatment === 'on';

  const isOfferBuilderDomainOffersEnabled =
    treatments[a4aFeatureKeys.admin.offerBuilder.domainOffers]?.treatment ===
    'on';

  return {
    isHelloWorldEnabled,
    isAlleElementsLibraryEnabled,
    isA4AOfferBuilderListPageEnabled,
    isA4ABusinessPageViewEnabled,
    isNewGlobalNavigationEnabled,
    isOfferHistoryEnabled,
    isOfferBuilderDomainOffersEnabled,
    isProfileActivityHistoryEnabled,
    isPreferredProviderEnabled,
    isMergePatientEnabled,
    isUnMergePatientEnabled,
    isViewEditsEnabled,
    isActiveAccountEnabled,
    isGiftCardChangeRequestEnabled,
    isAdminCheckoutTrackingEnabled,
    isGiftCardDetailsContentfulIsiEnabled,
    isPatientSummaryPointsCapEnabled,
    isCommunicationPreferencesSaveButtonEnabled,
    isNgrpAppreciationOfferEnabled,
    isConsumerDeactivationAndDeletionEnabled,
    isInviteNewBusinessOwnerButtonEnabled,
    isIamServiceEnabled,
    isLocationTransferTransactionStatusCardEnabled,
    isRepDispensedFlashEnabled,
    isA4ABusinessUserDeleteUserEnabled,
  };
};

export { useAdminFeatureFlags };
