import { useAdminFeatureFlags } from '@allergan-data-labs/admin-component-library/src/hooks/useAdminFeatureFlags';
import { SideMenu } from '@allergan-data-labs/admin-component-library/src/sideMenu/sideMenu';
import { AlleDownPage } from '@allergan-data-labs/component-library/src/components/errorPages/alleDownPage';
import { ErrorPage404 } from '@allergan-data-labs/component-library/src/components/errorPages/errorPage404';
import { ErrorPage503 } from '@allergan-data-labs/component-library/src/components/errorPages/errorPage503';
import { LoadingScreen } from '@allergan-data-labs/component-library/src/components/loadingScreen';
import { ErrorBoundary } from '@allergan-data-labs/component-library/src/errorBoundary/errorBoundary';
import { BuildingIcon } from '@allergan-data-labs/component-library/src/icons/buildingIcon';
import { Cash } from '@allergan-data-labs/component-library/src/icons/cash';
import { People } from '@allergan-data-labs/component-library/src/icons/people';
import { TagOutline } from '@allergan-data-labs/component-library/src/icons/tagOutline';
import { TrendingUp } from '@allergan-data-labs/component-library/src/icons/trendingUp';
import { NotificationBar } from '@allergan-data-labs/component-library/src/notificationBar/notificationBar';
import { SplitContext, SplitFactory } from '@splitsoftware/splitio-react';
import React from 'react';
import { Helmet } from 'react-helmet';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { A4APageContainer } from './a4a/components/pageContainer';
import { App } from './app';
import {
  isAlleDown,
  isInMaintenanceMode,
  splitAuthorizationKey,
} from './application.config';
import { OfferBuilderPermission } from './auth/permission';
import { SecureRoute } from './auth/secureRoute';
import { useAlleIam } from './auth/useAlleIam';
import { Routes } from './constants';
import { usePaymentsAdminFeatureFlags } from './hooks/usePaymentsAdminFeatureFlags';
import { Providers } from './providers';
import { routeConfig, RouteConfig } from './routeConfig';
import { useCanViewOfferDashboard } from './views/offerBuilder/queries/useCanViewOfferDashboard';

const RelativeContainer = styled.div`
  position: relative;
`;

const NavigationProxyComponent = () => {
  const { isNewGlobalNavigationEnabled } = useAdminFeatureFlags();

  return isNewGlobalNavigationEnabled ? (
    <Route component={A4APageContainer} />
  ) : (
    <Route component={AppSideMenu} />
  );
};

const AppSideMenu = () => {
  const history = useHistory();

  const { isEnabledPaymentsAdmin } = usePaymentsAdminFeatureFlags();
  const { isOfferBuilderDomainOffersEnabled } = useAdminFeatureFlags();
  const { isIamServiceEnabled } = useAdminFeatureFlags();
  const { isAllowed } = useAlleIam();
  let isOfferBuilderEnabled = useCanViewOfferDashboard();

  if (isIamServiceEnabled) {
    isOfferBuilderEnabled =
      isOfferBuilderDomainOffersEnabled &&
      isAllowed(OfferBuilderPermission.READ);
  }

  const onSignOut = React.useCallback(() => {
    history.push(Routes.logout);
  }, [history]);

  const getRoutes = () =>
    [
      {
        title: 'Consumers',
        icon: <People />,
        to: Routes.consumerSearch,
        visible: true,
      },
      {
        title: 'Businesses',
        icon: <BuildingIcon />,
        to: Routes.providerUserSearch,
        visible: true,
      },
      {
        title: 'Gift Card Lookup',
        icon: <TagOutline />,
        to: Routes.giftCardSearch,
        visible: true,
      },
      {
        title: 'Offer Builder',
        icon: <TrendingUp />,
        to: Routes.offerBuilderDashboard,
        visible: isOfferBuilderEnabled,
      },
      {
        title: 'Payments Admin',
        icon: <Cash />,
        to: Routes.paymentsAdmin,
        visible: isEnabledPaymentsAdmin,
      },
    ].filter((route) => route.visible);

  const routes = React.useMemo(getRoutes, [
    isOfferBuilderEnabled,
    isEnabledPaymentsAdmin,
  ]);

  return (
    <SideMenu homeRoute={Routes.home} routes={routes} onSignOut={onSignOut} />
  );
};

const secureRouter = (route: RouteConfig, component: any) => {
  return route.secure ? (
    <SecureRoute
      key="no-key"
      title={route.title}
      path={route.path}
      exact={route.exact}
      component={component}
    />
  ) : (
    <Route
      key="no-key"
      path={route.path}
      exact={route.exact}
      render={(props) => {
        const Component = component as any;
        return (
          <>
            <Helmet title={route.title ? route.title : 'Allē'} />
            <Component {...props} />
          </>
        );
      }}
    />
  );
};

const SplitLoader = ({ children }: { children: any }) => {
  const { isReady } = React.useContext(SplitContext);
  if (isReady) {
    return children;
  }
  return <LoadingScreen />;
};

const AppRouter = () => {
  if (isInMaintenanceMode) {
    return <ErrorPage503 />;
  }

  if (isAlleDown) {
    return <AlleDownPage />;
  }

  const segmentID = localStorage.getItem('ajs_anonymous_id');
  return (
    <SplitFactory
      config={{
        core: {
          authorizationKey: splitAuthorizationKey,
          key: segmentID ? JSON.parse(segmentID) : uuid(),
        },
      }}
      updateOnSdkUpdate={true}
      updateOnSdkTimedout={true}
    >
      <SplitLoader>
        <Router basename={process.env.PUBLIC_URL}>
          <ErrorBoundary>
            <React.Suspense fallback={<LoadingScreen />}>
              <Providers>
                <App>
                  <RelativeContainer>
                    <NotificationBar zIndex={1500} />

                    <Switch>
                      {routeConfig.map((route: RouteConfig) =>
                        route.secure
                          ? secureRouter(route, NavigationProxyComponent)
                          : null
                      )}
                      <Route render={() => null} />
                    </Switch>

                    <Switch>
                      {routeConfig.map((route: RouteConfig) =>
                        secureRouter(route, route.component)
                      )}
                      {/* Default to 404 page */}
                      <Route
                        render={() => <ErrorPage404 homeRoute={Routes.home} />}
                      />
                    </Switch>
                  </RelativeContainer>
                </App>
              </Providers>
            </React.Suspense>
          </ErrorBoundary>
        </Router>
      </SplitLoader>
    </SplitFactory>
  );
};

export { AppRouter };
