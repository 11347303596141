import { ReactLazyPreload } from '@allergan-data-labs/component-library/src/core/preload';

// constants
import { Routes } from './constants/routes';

export interface RouteConfig {
  path: Routes;
  title?: string;
  component: React.FunctionComponent<any>;
  secure?: boolean;
  exact: boolean;
}

const LoginView = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'LoginView' */ './views/login/loginView').then(
    (mod) => ({
      default: mod.LoginView,
    })
  )
);

const HomeView = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'HomeView' */ './views/homeView').then((mod) => ({
    default: mod.HomeView,
  }))
);

const PracticeSearchView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PracticeSearchView' */ './views/practiceSearch/practiceSearchView'
  ).then((mod) => ({
    default: mod.PracticeSearchView,
  }))
);

const ProviderUserSearchView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'ProviderUserSearchView' */ './views/providerUserSearch/providerUserSearchView'
  ).then((mod) => ({
    default: mod.ProviderUserSearchView,
  }))
);

const GiftCardSearchView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'GiftCardSearchView' */ './views/giftCardSearch/giftCardSearchView'
  ).then((mod) => ({
    default: mod.GiftCardSearchView,
  }))
);

const PatientView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PatientView' */ './views/patient/patientView'
  ).then((mod) => ({
    default: mod.PatientView,
  }))
);

const PatientOffersView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PatientOffersView' */ './views/patient/patientOffersView'
  ).then((mod) => ({
    default: mod.PatientOffersView,
  }))
);

const PatientOfferDetailsView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PatientOfferDetailsView' */ './views/patient/patientOfferDetailsView'
  ).then((mod) => ({
    default: mod.PatientOfferDetailsView,
  }))
);

const PatientGiftCardsView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PatientGiftCardsView' */ './views/patient/patientGiftCardsView'
  ).then((mod) => ({
    default: mod.PatientGiftCardsView,
  }))
);

const PatientGiftCardDetailsView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PatientGiftCardDetailsView' */ './views/patient/patientGiftCardDetailsView'
  ).then((mod) => ({
    default: mod.PatientGiftCardDetailsView,
  }))
);

const PointsCapView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PointsCapView' */ './views/legal/pointsCapView'
  ).then((mod) => ({
    default: mod.PointsCapView,
  }))
);

const GlobalIsiView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'GlobalIsiView' */ './views/legal/globalIsiView'
  ).then((mod) => ({
    default: mod.GlobalIsiView,
  }))
);

const CommunicationPreferencesView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'CommunicationPreferencesView' */ './views/patient/communicationPreferencesView'
  ).then((mod) => ({
    default: mod.CommunicationPreferencesView,
  }))
);

const PracticeView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PracticeView' */ './views/practice/practiceView'
  ).then((mod) => ({
    default: mod.PracticeView,
  }))
);

const UserView = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'User' */ './views/practice/user/userView').then(
    (mod) => ({
      default: mod.UserView,
    })
  )
);

const BusinessView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'BusinessView' */ './views/business/businessView'
  ).then((mod) => ({
    default: mod.BusinessView,
  }))
);

const ProviderUserView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'ProviderUserView' */ './views/providerUser/providerUserView'
  ).then((mod) => ({
    default: mod.ProviderUserView,
  }))
);

const PracticeReimbursementsView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PracticeReimbursementsView' */ './views/practice/reimbursements/practiceReimbursementsView'
  ).then((mod) => ({
    default: mod.PracticeReimbursementsView,
  }))
);

const PracticeReimbursementsStatementView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PracticeReimbursementStatementView' */ './views/practice/reimbursements/practiceReimbursementStatementView'
  ).then((mod) => ({
    default: mod.PracticeReimbursementStatementView,
  }))
);

const PracticeOffersView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PracticeOffersView' */ './views/practice/practiceOffersView'
  ).then((mod) => ({
    default: mod.PracticeOffersView,
  }))
);

const PracticeOfferDetailsView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PracticeOfferDetailsView' */ './views/practice/practiceOfferDetailsView'
  ).then((mod) => ({
    default: mod.PracticeOfferDetailsView,
  }))
);

const AppreciationOfferView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'AppreciationOfferView' */ './views/patient/appreciationOfferView'
  ).then((mod) => ({
    default: mod.AppreciationOfferView,
  }))
);

const EditTransactionView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'EditTransactionView' */ './views/editTransaction/editTransactionView'
  ).then((mod) => ({
    default: mod.EditTransactionView,
  }))
);

const LogoutView = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'LogoutView' */ './views/logout/logoutView').then(
    (mod) => ({
      default: mod.LogoutView,
    })
  )
);

const OfferBuilderRouter = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'OfferBuilderRouter' */ './views/offerBuilder/offerBuilderRouter'
  ).then((mod) => ({
    default: mod.OfferBuilderRouter,
  }))
);

const A4AOfferBuilderDashboard = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PatientSearchView' */ './views/a4a/offerBuilder/offerBuilderDashboard'
  ).then((mod) => ({
    default: mod.A4AOfferBuilderDashboard,
  }))
);

const OfferBuilderPreviewWallet = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'OfferBuilderPreviewWallet' */ './views/offerBuilder/offerBuilderPreviewWallet'
  ).then((mod) => ({
    default: mod.OfferBuilderPreviewWallet,
  }))
);

const OfferBuilderPreviewDetails = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'OfferBuilderPreviewDetails' */ './views/offerBuilder/offerBuilderPreviewDetails'
  ).then((mod) => ({
    default: mod.OfferBuilderPreviewDetails,
  }))
);

const PracticeWalletView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PracticeWalletView' */ './views/practice/wallet/practiceWalletView'
  ).then((mod) => ({
    default: mod.PracticeWalletView,
  }))
);

const ReceiptView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'ReceiptView' */ './views/practice/wallet/receipt/receiptView'
  ).then((mod) => ({
    default: mod.ReceiptView,
  }))
);

const PaymentsAdminView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PaymentsAdminView' */ './views/paymentsAdmin/paymentsAdminView'
  ).then((mod) => ({
    default: mod.PaymentsAdminView,
  }))
);

const PlatformComponentLibraryView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PlatformComponentLibrary' */ './views/debugViews/platformComponentLibrary'
  ).then((mod) => ({
    default: mod.PlatformComponentLibrary,
  }))
);

const A4AView = ReactLazyPreload(() =>
  import(/* webpackChunkName: 'A4AView' */ './views/a4a/a4aView').then(
    (mod) => ({
      default: mod.A4AView,
    })
  )
);

const PatientSearchView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PatientSearchView' */ './views/patientSearch/patientSearchView'
  ).then((mod) => ({
    default: mod.PatientSearchView,
  }))
);

const A4AConsumerSearchView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'A4AConsumerSearchView' */ './views/a4a/consumerSearch/consumerSearchView'
  ).then((mod) => ({
    default: mod.A4AConsumerSearchView,
  }))
);

const A4AGiftCardSearchView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PatientSearchView' */ './views/a4a/giftCardSearch/giftCardSearch'
  ).then((mod) => ({
    default: mod.A4AGiftCardSearchView,
  }))
);

const A4APaymentsAdminView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'PatientSearchView' */ './views/a4a/paymentsAdmin/paymentsAdminView'
  ).then((mod) => ({
    default: mod.A4APaymentsAdminView,
  }))
);

const A4ABusinessDetails = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'BusinessDetailsView' */ './views/a4a/businessDetails/businessDetailsView'
  ).then((mod) => ({
    default: mod.BusinessDetailsView,
  }))
);

const A4ALocationDetails = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'LocationDetailsView' */ './views/a4a/locationDetails/locationDetailsView'
  ).then((mod) => ({
    default: mod.LocationDetailsView,
  }))
);

const A4ABusinessSearchView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'A4ASearchResultsView' */ './views/a4a/businessSearch/businessSearch'
  ).then((mod) => ({
    default: mod.A4ABusinessSearch,
  }))
);

const A4ABusinessSearchResultsView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'businessSearchResults' */ './a4a/views/businessSearchResults/businessSearchResults'
  ).then((mod) => ({
    default: mod.A4ABusinessSearchResultsView,
  }))
);

const A4ALocationUsersView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'A4ALocationUsers' */ './a4a/views/locationUsers/locationUserTableView'
  ).then((mod) => ({
    default: mod.A4ALocationUserTableView,
  }))
);
const A4ABusinessUsers = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'A4ABusinessUsers' */ './a4a/views/businessUsers/businessUsers'
  ).then((mod) => ({
    default: mod.A4ABusinessUsersView,
  }))
);
const A4ABusinessUser = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'A4ABusinessUser' */ './a4a/views/businessUser/businessUser'
  ).then((mod) => ({
    default: mod.A4ABusinessUserView,
  }))
);
const A4ABusinessLocationsView = ReactLazyPreload(() =>
  import(
    /* webpackChunkName: 'A4ABusinessUsers' */ './a4a/views/businessLocations/businessLocations'
  ).then((mod) => ({
    default: mod.A4ABusinessLocationsView,
  }))
);

const routeConfig: RouteConfig[] = [
  {
    path: Routes.login,
    title: 'Login',
    component: LoginView,
    exact: true,
  },
  {
    path: Routes.home,
    component: HomeView,
    exact: true,
  },
  {
    path: Routes.debugPlatformComponentLibrary,
    title: 'DEBUG - Platform Component Library',
    component: PlatformComponentLibraryView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.providerUserSearch,
    title: 'Admin Allē for Business Search',
    component: ProviderUserSearchView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.consumerSearch,
    title: 'Search Consumers',
    component: PatientSearchView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.practiceSearch,
    title: 'Search Practices',
    component: PracticeSearchView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.giftCardSearch,
    title: 'Search Gift Cards',
    component: GiftCardSearchView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.consumerSummary,
    title: 'Consumer Summary',
    component: PatientView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.consumerOffers,
    title: 'Consumer Offers',
    component: PatientOffersView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.consumerOfferDetails,
    title: 'Offer Details',
    component: PatientOfferDetailsView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.consumerGiftCards,
    title: 'Consumer Gift Cards',
    component: PatientGiftCardsView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.consumerGiftCardDetails,
    title: 'Gift Cards Details',
    component: PatientGiftCardDetailsView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.giftCardDetails,
    title: 'Gift Cards Details',
    component: PatientGiftCardDetailsView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.a4aGiftCardDetailsNew,
    title: 'Gift Cards Details',
    component: PatientGiftCardDetailsView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.pointsAndEarning,
    title: 'Points and Earning',
    component: PointsCapView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.globalIsi,
    title: 'Important Safety Information',
    component: GlobalIsiView,
    secure: false,
    exact: true,
  },
  {
    path: Routes.consumerCommunicationPreferences,
    title: 'Communication Preferences',
    component: CommunicationPreferencesView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.practice,
    title: 'Practice Summary',
    component: PracticeView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.user,
    title: 'User Summary',
    component: UserView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.providerUser,
    title: 'Provider User Summary',
    component: ProviderUserView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.providerBusinessSummary,
    title: 'Business Summary',
    component: BusinessView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.practiceReimbursements,
    title: 'Practice Reimbursements',
    component: PracticeReimbursementsView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.a4aPracticeReimbursements,
    title: 'Practice Reimbursements',
    component: PracticeReimbursementsView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.practiceReimbursementStatement,
    title: 'Practice Reimbursement Statement',
    component: PracticeReimbursementsStatementView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.a4aPracticeReimbursementStatement,
    title: 'Practice Reimbursement Statement',
    component: PracticeReimbursementsStatementView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.practiceWallet,
    title: 'Practice Wallet',
    component: PracticeWalletView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.a4aPracticeWallet,
    title: 'Practice Wallet',
    component: PracticeWalletView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.practiceReceipt,
    title: 'Receipt',
    component: ReceiptView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.a4aPracticeReceipt,
    title: 'Receipt',
    component: ReceiptView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.practiceOffers,
    title: 'Practice Offers',
    component: PracticeOffersView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.a4aPracticeOffers,
    title: 'Practice Offers',
    component: PracticeOffersView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.practiceOfferDetails,
    title: 'Offer Details',
    component: PracticeOfferDetailsView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.a4aPracticeOfferDetails,
    title: 'Offer Details',
    component: PracticeOfferDetailsView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.consumerAppreciationOffer,
    title: 'Appreciation Offer',
    component: AppreciationOfferView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.practiceEditTransaction,
    title: 'Apply Offer',
    component: EditTransactionView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.a4aPracticeEditTransaction,
    title: 'Apply Offer',
    component: EditTransactionView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.consumerEditTransaction,
    title: 'Apply Offer',
    component: EditTransactionView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.logout,
    title: 'Logout',
    component: LogoutView,
    exact: true,
  },
  {
    path: Routes.offerBuilder,
    title: 'Offer Builder',
    component: OfferBuilderRouter,
    secure: true,
    exact: false,
  },
  {
    path: Routes.offerBuilderPreviewWallet,
    title: 'Offer Preview | Wallet',
    component: OfferBuilderPreviewWallet,
    secure: true,
    exact: true,
  },
  {
    path: Routes.a4aOfferBuilderPreviewWallet,
    title: 'Offer Preview | Wallet',
    component: OfferBuilderPreviewWallet,
    secure: true,
    exact: true,
  },
  {
    path: Routes.offerBuilderPreviewDetails,
    title: 'Offer Preview | Details',
    component: OfferBuilderPreviewDetails,
    secure: true,
    exact: true,
  },
  {
    path: Routes.a4aOfferBuilderPreviewDetails,
    title: 'Offer Preview | Details',
    component: OfferBuilderPreviewDetails,
    secure: true,
    exact: true,
  },
  {
    path: Routes.paymentsAdmin,
    title: 'Payments Admin',
    component: PaymentsAdminView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.a4a,
    title: 'A4A',
    component: A4AView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.a4aConsumerSearch,
    title: 'A4A Search Consumer',
    component: A4AConsumerSearchView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.a4aConsumerDetails,
    title: 'Consumer Summary',
    component: PatientView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.a4aBusiness,
    title: 'A4A Business Search',
    component: A4ABusinessSearchView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.a4aBusinessSearch,
    title: 'A4A Business Search',
    component: A4ABusinessSearchResultsView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.a4aOfferBuilder,
    title: 'Offer Builder',
    component: A4AOfferBuilderDashboard,
    secure: true,
    exact: false,
  },
  {
    path: Routes.a4aGiftCardSearch,
    title: 'A4A Gift Card Search',
    component: A4AGiftCardSearchView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.a4aPaymentsAdmin,
    title: 'A4A Payment Admin',
    component: A4APaymentsAdminView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.a4aLocationDetails,
    title: 'A4A Location Details',
    component: A4ALocationDetails,
    secure: true,
    exact: true,
  },
  {
    path: Routes.a4aBusinessDetails,
    title: 'A4A Business Details',
    component: A4ABusinessDetails,
    secure: true,
    exact: true,
  },
  {
    path: Routes.a4aLocationDetails,
    title: 'A4A Location Details',
    component: A4ALocationDetails,
    secure: true,
    exact: true,
  },
  {
    path: Routes.a4aLocationUsers,
    title: 'A4a Location Users',
    component: A4ALocationUsersView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.a4aBusinessUsers,
    title: 'A4A Business Users',
    component: A4ABusinessUsers,
    secure: true,
    exact: true,
  },
  {
    path: Routes.a4aBusinessLocations,
    title: 'A4A Business Locations',
    component: A4ABusinessLocationsView,
    secure: true,
    exact: true,
  },
  {
    path: Routes.a4aBusinessUser,
    title: 'A4A Business User',
    component: A4ABusinessUser,
    secure: true,
    exact: true,
  },
];

export { routeConfig };
