import { getEnvConfig } from './getEnvConfig';
import { graphQLProxyUrls } from './application.config';
import {
  AlleApolloClient,
  ApolloClientFactory,
  WriteDataOptions,
} from './client';
import { Resolvers, Operation } from 'apollo-boost';

export interface WebClientConfig {
  name?: string;
  version?: string;
  getAccessToken: () => Promise<string | undefined>;
  resolvers?: Resolvers | Resolvers[];
  initialCache?: WriteDataOptions<any>;
  overrideRequest?: (operation: Operation) => void;
  onError?: Parameters<typeof ApolloClientFactory>[0]['onError'];
}

const uri = getEnvConfig(graphQLProxyUrls, process.env.REACT_APP_ALLE_PROXY);

export function getDefaultHeaders(userId?: string) {
  let adlSid = 'app:admin-web';
  // @ts-ignore
  if (window.analytics?.user) {
    // @ts-ignore
    const anonymousId = window.analytics?.user().anonymousId();
    if (anonymousId) {
      adlSid = `anon:${anonymousId}#${adlSid}`;
    }
  }
  if (userId) {
    adlSid = `user:${userId}#${adlSid}`;
  }
  const headers = {
    'ADL-SID': adlSid,
  };
  return headers;
}

const AlleApolloClientWeb = ({
  name,
  version,
  getAccessToken,
  resolvers,
  initialCache,
  overrideRequest,
  onError,
}: WebClientConfig) => {
  const request = async (operation: Operation) => {
    const headers = getDefaultHeaders();
    const token = await getAccessToken();
    if (token) {
      operation.setContext({
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      operation.setContext({
        headers,
      });
    }
  };
  return AlleApolloClient(
    {
      name,
      version,
      uri,
      request: overrideRequest ?? request,
      onError,
    },
    {
      ...(initialCache ? { initialCache } : {}),
      ...(resolvers ? { resolvers } : {}),
    }
  );
};

// Create a client instance without affecting the global client
const ApolloClientWebFactory = ({
  name,
  version,
  getAccessToken,
  resolvers,
  initialCache,
  overrideRequest,
  onError,
}: WebClientConfig) => {
  const request = async (operation: Operation) => {
    const token = await getAccessToken();
    if (token) {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  };
  return ApolloClientFactory(
    {
      name,
      version,
      uri,
      request: overrideRequest ?? request,
      onError,
    },
    {
      ...(initialCache ? { initialCache } : {}),
      ...(resolvers ? { resolvers } : {}),
    }
  );
};

export { AlleApolloClientWeb, ApolloClientWebFactory };
