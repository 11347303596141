import React from 'react';
import { useHistory } from 'react-router';
import { assign } from 'xstate';
import { useMachine } from '@xstate/react';
import gql from 'graphql-tag';

// shared sdk
import { client } from '@allergan-data-labs/shared-sdk/src/client';
import { ApolloClientWebFactory } from '@allergan-data-labs/shared-sdk/src/client-web';
import { logger } from '@allergan-data-labs/component-library/src/datadog/dataDog';

// shared components
import { AuthContext } from './AuthContext';
import {
  authMachine,
  AuthMachineContext,
  AuthMachineEvent,
} from './authMachine';

// components
import {
  validateToken as validateTokenOkta,
  closeSession as closeSessionOkta,
  oktaAuth,
  subscribeToErrorEvents,
  unsubscribeToErrorEvents,
} from './oktaClient';
import { allerganAdvantageClient } from './allerganAdvantageClient';

// constants
import { Routes } from '../constants/routes';
import { oktaTokenIds } from '../application.config';
import { useViewerPermissionsLazyQuery } from './useViewerPermissionsQuery';
import { Permission } from './permission';

const AuthProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const history = useHistory();
  const { loadPermissions, permissions } =
    useViewerPermissionsLazyQuery<Permission>();

  const [authState, sendAuthEvent] = useMachine(
    authMachine.withConfig({
      services: {
        verifyOktaLoggedIn: validateTokenOkta,
        verifyAllerganAdvantageLoggedIn: allerganAdvantageClient.validateToken,
        verifyAlleAuthLoggedIn: async () => {
          logger.error('Alle auth not yet supported by admin app', {
            id: 'AuthProvider.authMachine.services.verifyAlleAuthLoggedIn',
          });
          throw new Error('Alle auth not yet supported by admin app');
        },
        loginWithAllerganAdvantageToken: async (_, event) => {
          const { allerganAdvantageToken } = event;
          if (!allerganAdvantageToken) {
            logger.error('No token present', {
              id: 'AuthProvider.authMachine.services.loginWithAllerganAdvantageToken',
            });
            throw new Error('No token present');
          }

          // Attempt to fetch the admin viewer ID using the new token
          const apolloClient = ApolloClientWebFactory({
            getAccessToken: () => Promise.resolve(allerganAdvantageToken),
          });
          const res = await apolloClient.query({
            query: gql`
              query PracticeEnrolledAt {
                viewer {
                  id
                }
              }
            `,
          });
          if (!res.data?.viewer?.id) {
            logger.error('Invalid token provided', {
              id: 'AuthProvider.authMachine.services.loginWithAllerganAdvantageToken',
            });
            throw new Error('Invalid token provided');
          }
          return {
            allerganAdvantageToken,
          };
        },
        clearCache: async () => {
          await client.clearStore();
        },
        logout: async () => {
          logger.info('Started logout', {
            id: 'AuthProvider.authMachine.services.logout',
          });
          // Run these in parallel so none are waiting for the others
          await Promise.allSettled([
            // Logout AA
            Promise.resolve(allerganAdvantageClient.signOut()),
            // Logout Okta
            closeSessionOkta(),
          ]);
        },
      },
      actions: {
        redirectToLogout: () => {
          logger.info('Started action to redirectToLogout', {
            id: 'AuthProvider.authMachine.actions.redirectToLogout',
          });
          history.push(Routes.logout);
        },
        storeOktaToken: (_, event) => {
          const { idToken, accessToken } = event;
          oktaAuth.tokenManager.add(oktaTokenIds.idToken, idToken);
          oktaAuth.tokenManager.add(oktaTokenIds.accessToken, accessToken);
        },
        storeAllerganAdvantageToken: (_, event) => {
          event.data?.allerganAdvantageToken &&
            allerganAdvantageClient.storeToken(
              event.data?.allerganAdvantageToken
            );
        },
        // TODO: Implement these two when Alle auth is needed in admin-web
        storeAlleAuthOktaTokens: () => {},
        storeAlleAuthAccessToken: () => {},
        setIDPOkta: assign<AuthMachineContext, AuthMachineEvent>({
          idp: 'OKTA',
        }),
        setIDPAllerganAdvantage: assign<AuthMachineContext, AuthMachineEvent>({
          idp: 'ALLERGAN_ADVANTAGE',
        }),
        setIDPAlleAuth: assign<AuthMachineContext, AuthMachineEvent>({
          idp: 'ALLE_AUTH',
        }),
        clearIDP: assign<AuthMachineContext, AuthMachineEvent>({
          idp: null,
        }),
        loadPermissions: () => {
          loadPermissions();
        },
      },
      activities: {
        checkTokenExpiration: () => {
          if (allerganAdvantageClient.getAccessToken()) {
            const tokenInvalidation =
              allerganAdvantageClient.subscribeToTokenInvalidation(() => {
                logger.info('Started activities to logout', {
                  id: 'AuthProvider.authMachine.activities.checkTokenExpiration.logout',
                });
                history.push(Routes.logout);
              });

            return () => tokenInvalidation();
          }
        },
      },
    })
  );

  React.useEffect(() => {
    subscribeToErrorEvents(() => {
      sendAuthEvent('LOGOUT');
    });
    return () => {
      unsubscribeToErrorEvents();
    };
  }, [sendAuthEvent]);

  return (
    <AuthContext.Provider
      value={{
        authState,
        sendAuthEvent,
        permissions,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
