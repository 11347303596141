import { a4aFeatureKeys } from '@allergan-data-labs/split-feature-flag/src/admin';
import { useAlleTreatments } from '@allergan-data-labs/split-feature-flag/src/useAlleTreatments';

const useAdminFeatureFlags = () => {
  const treatments = useAlleTreatments({
    splitNames: [
      a4aFeatureKeys.admin.a4aHelloWorld,
      a4aFeatureKeys.admin.a4aAlleElementsLibraryEnabled,
      a4aFeatureKeys.admin.transactionOptions.viewEdits,
      a4aFeatureKeys.admin.transactionOptions.editTransaction,
      a4aFeatureKeys.admin.managePatientLinks.activateAccount,
      a4aFeatureKeys.admin.offerBuilder.domainOffers,
      a4aFeatureKeys.admin.offerBuilder.giftCardsChangeRequestEndDateToggle,
      a4aFeatureKeys.admin.offerBuilder.repDispensedFlashEnabled,
      a4aFeatureKeys.admin.offerBuilder.isAllBrandsRequired,
      a4aFeatureKeys.admin.communicationPreferences.saveButton,
      a4aFeatureKeys.admin.communicationPreferences.enabledEmail,
      a4aFeatureKeys.admin.communicationPreferences.push,
      a4aFeatureKeys.admin.communicationPreferences.sms,
      a4aFeatureKeys.admin.useIamService,
      a4aFeatureKeys.admin.practice.locationTransferTransactionStatusCard,
      a4aFeatureKeys.admin.a4aBusinessPageViewEnabled,
      a4aFeatureKeys.admin.a4aNewGlobalNavigationEnabled,
    ],
  });

  const isHelloWorldEnabled =
    treatments[a4aFeatureKeys.admin.a4aHelloWorld]?.treatment === 'on';

  const isAlleElementsLibraryEnabled =
    treatments[a4aFeatureKeys.admin.a4aAlleElementsLibraryEnabled]
      ?.treatment === 'on';

  const isViewEditsEnabled =
    treatments[a4aFeatureKeys.admin.transactionOptions.viewEdits]?.treatment ===
    'on';

  const isActiveAccountEnabled =
    treatments[a4aFeatureKeys.admin.managePatientLinks.activateAccount]
      ?.treatment === 'on';

  const isGiftCardChangeRequestEnabled =
    treatments[
      a4aFeatureKeys.admin.offerBuilder.giftCardsChangeRequestEndDateToggle
    ]?.treatment === 'on';

  const isPreferencesSaveButtonEnabled =
    treatments[a4aFeatureKeys.admin.communicationPreferences.saveButton]
      ?.treatment === 'on';

  const isPreferencesSmsEnabled =
    treatments[a4aFeatureKeys.admin.communicationPreferences.sms]?.treatment ===
    'on';

  const isPreferencesPushEnabled =
    treatments[a4aFeatureKeys.admin.communicationPreferences.push]
      ?.treatment === 'on';

  const isPreferencesEmailNotificationEnabled =
    treatments[a4aFeatureKeys.admin.communicationPreferences.enabledEmail]
      ?.treatment === 'on';

  const isEditTransactionEnabled =
    treatments[a4aFeatureKeys.admin.transactionOptions.editTransaction]
      ?.treatment === 'on';

  const isIamServiceEnabled =
    treatments[a4aFeatureKeys.admin.useIamService]?.treatment === 'on';

  const isLocationTransferTransactionStatusCard =
    treatments[
      a4aFeatureKeys.admin.practice.locationTransferTransactionStatusCard
    ]?.treatment === 'on';

  const isRepDispensedFlashEnabled =
    treatments[a4aFeatureKeys.admin.offerBuilder.repDispensedFlashEnabled]
      ?.treatment === 'on';

  const isAllBrandsRequiredEnabled =
    treatments[a4aFeatureKeys.admin.offerBuilder.isAllBrandsRequired]
      ?.treatment === 'on';

  const isNewGlobalNavigationEnabled =
    treatments[a4aFeatureKeys.admin.a4aNewGlobalNavigationEnabled]
      ?.treatment === 'on';

  const isOfferBuilderDomainOffersEnabled =
    treatments[a4aFeatureKeys.admin.offerBuilder.domainOffers]?.treatment ===
    'on';

  return {
    isHelloWorldEnabled,
    isAlleElementsLibraryEnabled,
    isViewEditsEnabled,
    isActiveAccountEnabled,
    isGiftCardChangeRequestEnabled,
    isPreferencesSaveButtonEnabled,
    isPreferencesSmsEnabled,
    isPreferencesPushEnabled,
    isPreferencesEmailNotificationEnabled,
    isEditTransactionEnabled,
    isIamServiceEnabled,
    isLocationTransferTransactionStatusCard,
    isRepDispensedFlashEnabled,
    isAllBrandsRequiredEnabled,
    isNewGlobalNavigationEnabled,
    isOfferBuilderDomainOffersEnabled,
  };
};

export { useAdminFeatureFlags };
