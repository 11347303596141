import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { getColorToken, Tooltip } from '@allergan-data-labs/alle-elements';

const MAX_WIDTH = 206;

interface UserInfoProps {
  title: string;
  subTitle?: string;
}

const UserInfo: React.FunctionComponent<UserInfoProps> = ({
  title,
  subTitle,
}) => {
  return (
    <Box
      maxWidth={MAX_WIDTH}
      data-testid="user-info-container"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Tooltip title={title}>
        <Text
          isTruncated
          fontFamily="Graphik"
          fontSize="16px"
          fontWeight="500"
          lineHeight="24px"
          margin={0}
          color={getColorToken('Text/Neutral/Default')}
        >
          {title}
        </Text>
      </Tooltip>

      {subTitle && (
        <Text
          fontFamily="Graphik"
          fontSize="12px"
          margin={0}
          color={getColorToken('Text/Neutral/Support')}
          data-testid="user-info-subtitle"
        >
          {subTitle}
        </Text>
      )}
    </Box>
  );
};

export { UserInfo };
